import { media, getTypography } from '@creditas-ui/utilities'
import { Container, Grid as CreditasGrid, GridItem } from '@creditas/layout'
import { Typography } from 'components/Typography'
import { styled, css } from '@creditas-ui/system'
import { getColorToken } from 'src/utils/getColorToken'

const Wrapper = styled(Container)``

const Header = styled(GridItem)`
  margin-top: 24px;
  margin-bottom: 22px;

  ${media.up('5xl')} {
    margin-top: 58px;
    margin-bottom: 57px;
  }

  @media (min-width: 1400px) {
    margin-top: 82px;
    margin-bottom: 114px;
  }
`

const Title = styled(Typography)`
  color: ${getColorToken('neutral.90')};
  margin-bottom: 16px;

  ${getTypography('headingMdLight')};

  strong {
    ${getTypography('headingMdBold')};
  }

  ${media.up('5xl')} {
    margin-bottom: 24px;
    ${getTypography('heading2xlLight')};

    strong {
      ${getTypography('heading2xlBold')};
    }
  }
`

const Description = styled(Typography)`
  color: ${getColorToken('neutral.60')};
  ${getTypography('bodyMdRegular')};

  ${media.up('5xl')} {
    ${getTypography('bodyXlLight')};
  }
`

const Image = styled.img`
  width: 100%;

  z-index: -1;

  @media (min-width: 728px) {
    margin-top: 40px;
  }
`

const CardsShelf = styled.div(
  () => css`
    display: flex;
    justify-content: flex-start;
    overflow: auto;
    width: auto;
    margin-left: -16px;
    margin-top: -65px;
    margin-right: -16px;
    padding: 0 0 40px;

    @media (min-width: 728px) {
      overflow: auto;
      margin-top: 0px;
      padding: 144px 0;
      margin-left: -115px;
      padding-left: 100px;
      width: calc(100vw + 100px);
    }

    &:after,
    &:before {
      content: '';
      display: block;
      min-width: 1px;
    }

    &:before {
      margin-right: 8px;
      margin-left: -1px;
    }

    &:after {
      margin-left: 8px;
      margin-right: -1px;
    }

    & > * {
      width: 236px;
      min-width: 236px;
      margin: 0 8px;

      ${media.up('5xl')} {
        width: 288px;
        min-width: 288px;
      }
    }
  `,
)

const gridOptions = {
  gridPadding: ['0'],
  gridColumns: {
    xs: 'repeat(4, 1fr)',
    md: 'repeat(12, 1fr)',
  },
  gridRows: {
    xs: 'max-content',
    md: 'max-content',
  },
  gridAreas: {
    xs: `
      "${'header '.repeat(4)}"
      "${'image '.repeat(4)}"
      "${'children '.repeat(4)}"
    `,
    md: `
      ". ${'header '.repeat(5)} . ${'image '.repeat(5)}"
      "${'children '.repeat(7)} ${'image '.repeat(5)}"
    `,
  },
}

const Grid = styled(CreditasGrid)`
  @media(min-width: 728px) {
    grid-template-columns: repeat(12, 1fr);
    grid-template-areas: ". ${'header '.repeat(5)} . ${'image '.repeat(5)}"
                         "${'children '.repeat(7)} ${'image '.repeat(5)}"
  }
`

export {
  Wrapper,
  Header,
  Title,
  Description,
  Image,
  CardsShelf,
  gridOptions,
  Grid,
}
