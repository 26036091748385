import React from 'react'
import { graphql } from 'gatsby'
import { object } from 'prop-types'
import { Layout } from 'ui/Layout'
import { HeroImage } from 'components/HeroImage'
import Highlights from 'components/Highlights'
import heroImage from 'images/hero-image.png'
import InvestorForm from 'components/InvestorForm'
import { SITE_SECTIONS } from 'src/constants'

const heroImages = {
  src: heroImage,
  alt: 'Devices on a table showing the Creditas investor website Image',
  description: 'Smartphone Image',
}

const metatags = {
  slug: '/',
  title: 'Creditas | Investor Relations',
  description:
    'Visit our investor relations portal and stay on top of the main news and information about Creditas.',
}

const propTypes = {
  data: object,
}

const Home = ({ data }) => {
  const {
    allContentfulCategory: { nodes: allCategories = [] },
    allContentfulHighlight: { nodes: highlights = [] },
  } = data

  const categories = allCategories.filter(({ isVisibleTo }) =>
    isVisibleTo?.includes(SITE_SECTIONS.CARDS),
  )

  return (
    <Layout metatags={metatags}>
      <HeroImage
        title="<strong>Investor</strong> relations"
        description="What do you want to accomplish today?"
        images={heroImages}
        cards={categories}
      />

      <Highlights
        title="Highlights"
        subTitle="Our latest results"
        posts={highlights}
      />

      <InvestorForm />
    </Layout>
  )
}

export const pageQuery = graphql`
  query ContentfulCategories {
    allContentfulCategory(filter: { isVisibleTo: { ne: null } }) {
      nodes {
        id
        title
        slug
        isSubcategory
        isVisibleTo
        category: sectionSubcategories {
          id
        }
      }
    }
    allContentfulHighlight(sort: { fields: createdAt, order: ASC }) {
      nodes {
        title
        description
      }
    }
  }
`

Home.propTypes = propTypes

export default Home
